import React from "react"

import Layout from "../components/layout"
import { Location } from "@reach/router"
import SEO from "../components/seo"

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const NotFoundPage = ({ data }: Props) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Layout location={location}>
            <SEO title="404: Not Found" />
            <h1>Not Found</h1>
            <p>You just hit a route that doesn't exist... the sadness.</p>
          </Layout>
        )
      }}
    </Location>
  )
}

export default NotFoundPage
